
import {
    defineComponent, ref
} from 'vue';
import {
    country, phoneCode, setting,
    community
} from '@/data';
import { isCNServer } from '@/util/location';
import { BasicFormData, BasicFormDataKey, SettingResponseData } from '@/views/pm/setting/all.type';
import HttpRequest from '@/util/axios.config';
import { setting as settingRule } from '@/methods/rule';
import noticeBase from '@/util/notice.base';
import { ChangeWordList } from '@/methods/setText';

export default defineComponent({
    components: {},
    setup() {
        // 判断新旧社区
        const { isNew } = community;
        // 办公、社区标志位
        const projectType = community.ProjectType;
        const formRef = ref<any>();
        const formData = ref<BasicFormData>({
            Location: '',
            Street: '',
            City: '',
            States: '',
            Country: '',
            PostalCode: '',
            EnableUserPin: '',
            AptPinType: '',
            DevOfflineNotify: '',
            EnableSIMWarning: '',
            PhoneCode: '',
            MobileNumber: '',
            LimitCreatePin: '',
            TriggerAction: '0'
        });

        const isShowExpireTip = ref(false);

        setting.getPMCommunityDetail((res: BasicFormData) => {
            Object.keys(formData.value).forEach((item) => {
                const key = item as BasicFormDataKey;
                if (res[key] !== null) {
                    formData.value[key] = res[key];
                }
            });
            // 没有高级功能显示的都是on,off且过期显示过期提示语
            if (formData.value.LimitCreatePin === '0') {
                formData.value.EnableUserPin = '1';
            } else if (String(formData.value.EnableUserPin) === '0' && formData.value.LimitCreatePin === '2') {
                isShowExpireTip.value = true;
                formData.value.EnableUserPin = '0';
            } else {
                formData.value.EnableUserPin = String(formData.value.EnableUserPin);
            }
            if (isCNServer()) {
                formData.value.PhoneCode = '86';
            }
        });

        const rules = {
            PhoneCode: [
                {
                    required: true,
                    message: WordList.ProperAllTextRuleRegionEmpty,
                    trigger: 'blur'
                }
            ],
            MobileNumber: [
                {
                    required: true,
                    message: WordList.RulePhoneEmpty,
                    trigger: 'blur'
                }
            ],
            PostalCode: [
                {
                    validator: settingRule.onlyNumberAndNotEmpty,
                    trigger: 'change'
                }
            ]
        };

        const countryOption = addressOption;

        const submit = () => {
            if (isShowExpireTip.value) {
                noticeBase.messageBox('alert', WordList.ProperAllTextFeatureExpireTips, '', 'warning')(() => false);
                return;
            }
            formRef.value.validate((valid: boolean) => {
                if (valid) {
                    HttpRequest.post(`v3/web/${community.ProjectType.value}/project/editProjectInfo`, formData.value, (res: any) => {
                        if (res.data.reload === 1) {
                            setTimeout(() => {
                                window.location.reload();
                            }, 1000);
                        }
                    });
                }
            });
        };

        const changeUserPin = () => {
            // 1.不存在高级功能，只显示on 2.过期且切换到off，显示提示语且无法切换 3.其余情况正常切换
            if (formData.value.LimitCreatePin === '0') {
                noticeBase.messageBox('alert', WordList.ProperAllTextactivateFeatureTips, WordList.PersonuserInfo, 'warning')(() => false);
                formData.value.EnableUserPin = '1';
            } else if (formData.value.LimitCreatePin === '2' && formData.value.EnableUserPin === '0') {
                formData.value.EnableUserPin = '1';
                isShowExpireTip.value = true;
            } else {
                isShowExpireTip.value = false;
            }
        };

        const limitMobileNumberType = (val: string) => {
            const reg = /[^\d]]*/g;
            formData.value.MobileNumber = val.replace(reg, '');
        };

        return {
            formRef,
            formData,
            country,
            phoneCode,
            isCNServer: isCNServer(),
            rules,
            countryOption,
            submit,
            isShowExpireTip,
            changeUserPin,
            limitMobileNumberType,
            ChangeWordList,
            projectType,
            isNew,
            community
        };
    }
});
